@import url("https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@1&family=Playfair+Display:wght@700&family=Tilt+Neon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Radio+Canada+Big:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

@import url("/src/components/App/App.css");
@import url("/src/components/Blog/BlogComponents/BlogAbout/BlogAbout.css");
@import url("/src/components/Blog/BlogComponents/BlogCta/BlogCta.css");
@import url("/src/components/Blog/BlogComponents/GeneralBlog/GeneralBlog.css");
@import url("/src/components/Blog/Blogs/Blogs.css");
@import url("/src/components/BookOnlineButton/BookOnlineButton.css");
@import url("/src/components/Dropdown/Dropdown.css");
@import url("/src/components/EmailListForm/EmailListForm.css");
@import url("/src/components/Footer/Footer.css");
@import url("/src/components/Gallery/GalleryCta.css");
@import url("/src/components/Gallery/GeneralGallery.css");
@import url("/src/components/Gallery/ImageGallery.css");
@import url("/src/components/Header/Header.css");
@import url("/src/components/Main/GameReview/GameReview.css");
@import url("/src/components/Main/LatestNews/LatestNews.css");
@import url("/src/components/Main/Main.css");
@import url("/src/components/Modal/ModalEmailList/ModalEmailList.css");
@import url("/src/components/Modal/ModalTemplate/ModalTemplate.css");
@import url("/src/components/Pages/Soccer/Mens/Mls/FcDallas/FcDallas.css");
@import url("/src/components/SidebarDropdown/SidebarDropdownBulletpoint/SidebarDropdownBulletpoint.css");
@import url("/src/components/SidebarDropdown/SidebarDropdownItem/SidebarDropdownItem.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f1f2f3; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
