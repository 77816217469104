.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 40px 100px;
  background: black;
}

.footer__links {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 50%;
  justify-content: center;
}

.footer__column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer__column-title {
  color: white;
  margin: 0 0 20px;
  font-size: 20px;
  pointer-events: none;
}

.footer__column-link {
  margin: 0;
  color: white;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateX(10px);
    /* color: red; */
  }
}

@media screen and (max-width: 1240px) {
  .footer {
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    flex-direction: column;
    gap: 100px;
    padding: 40px 20px 100px;
  }
  .footer__links {
    width: 100%;
    max-width: 520px;
    justify-content: space-between;
    margin: 0 auto 0;
  }
  .email-list {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .footer {
    gap: 80px;
  }
  .footer__links {
    flex-direction: column;
    gap: 60px;
  }
  .footer__column-title {
    margin: 0 0 10px;
  }
}

@media screen and (max-width: 410px) {
  .footer__links {
    justify-content: space-between;
  }
  .footer__column-title {
    margin: 0 0 10px;
    font-size: 16px;
  }
  .footer__column-link {
    font-size: 17px;
  }
}
