.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  /* padding: 0 40px 0; */
  /* background: #333333; */
  width: 100%;
  height: 90px;
  background: white;
  box-shadow: none;
  /* transition: box-shadow 0.3s ease, background 0.3s ease; */
  transition: box-shadow 0.3s ease, all 0.3s ease;
  /* position: sticky;
      top: 0;
      z-index: 99; */
}

.header.scrolled {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    white 95%,
    rgba(211, 211, 211, 0.5) 100%
  );
  height: 70px;
}

.header__container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 60px;
  height: 90px;
  width: calc(100% - 100px);
  max-width: 1400px;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.header__container.scrolled {
  height: 70px;
}

.header__logo-link {
  text-decoration: none;
  color: #f5f0f0;
}

.header__icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
}

.header__icon {
  cursor: pointer;
  height: 26px;
}

.header__logo {
  height: 40px;
  cursor: pointer;
  position: relative;
  z-index: 4;
}

.header__navbar {
  position: relative;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: 30px;
  list-style-type: none;
  font-size: 24px;
  margin: 0;
  justify-content: center;
  align-items: center;

  /* position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto; */
}

.header__list-item-menu {
  cursor: pointer;
  /* transition: color 0.15s ease-in; */
  font-size: 22px;
  font-weight: 600;
  font-family: "poppins";
  /* color: rgb(245, 240, 240); */
  color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.header__list-item-menu-hovered {
  cursor: pointer;
  /* transition: color 0.15s ease-in; */
  font-size: 22px;
  font-weight: 600;
  font-family: "poppins";
  height: 66px;
  /* display: flex;
  flex-direction: row; */
  /* align-items: flex-start; */
  /* margin-top: 37px; */
  color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.header__list-item-menu-hovered:hover {
  cursor: pointer;
  /* color: #dde4eb; */
}

.header__list-item-triangle {
  /* background: url(../../images/icons/triangle.png) center no-repeat; */
  background: url(../../images/icons/orangeTriangleTwo.svg) center no-repeat;
  height: 12px;
  width: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  transform: rotate(90deg);
  transform-origin: center;
  animation: firstRotation 0.2s linear forwards;
}

.header__list-item-triangle-hovered {
  animation: secondRotation 0.2s linear forwards;
}

@keyframes firstRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes secondRotation {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.header__list-item {
  cursor: pointer;
  /* color: #fefefa; */
  color: black;
  transition: all 0.15s ease-in;
  font-family: "Nunito", sans-serif;
}

.header__list-item:hover {
  color: #dde4eb;
}

.menu__container {
  display: none;
  visibility: hidden;
  pointer-events: none;
  transition: box-shadow 0.3s ease, all 0.3s ease;
  height: 105px;
}

.menu__container.scrolled {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    white 95%,
    rgba(211, 211, 211, 0.5) 100%
  );
  height: 70px;
}

.navbar {
  background-color: transparent;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto 0;
}

.menu-bars {
  margin: 0;
  font-size: 2rem;
  color: black;
  background: none;
  cursor: pointer;
}

.nav-menu {
  background-color: #0d4675;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-menu__backdrop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
  visibility: hidden;
}

.nav-menu__backdrop__open {
  visibility: visible;
  opacity: 1;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: rgba(6, 57, 99, 1);
  color: #dde4eb;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 5;
  background-color: #f1f2f3;
}

.navbar-toggle {
  background-color: #f1f2f3;
  padding-left: 30px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.nav-menu__button {
  width: 230px;
  margin: 20px 0 0 10px;
  font-size: 15px;
}

@media screen and (max-width: 1300px) {
  .header__navbar {
    position: relative;
  }
}

@media screen and (max-width: 950px) {
  .header {
    height: 90px;
    /* height: 70px; */
    /* padding: 0 20px 0; */
  }
  .header__container {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .menu__container {
    display: block;
    visibility: visible;
    pointer-events: all;
    height: 90px;
  }
  .header__logo {
    height: 40px;
  }
  .react-parallax {
    z-index: -10;
  }
  .k-cabinets__color-section {
    position: relative;
    z-index: 0;
  }
  .nav-menu {
    z-index: 2;
  }
}

@media screen and (max-width: 600px) {
  .nav-text {
    height: 50px;
  }
}

/* @media screen and (max-width: 560px) {
  .header__logo {
    height: 30px;
  }
} */

@media screen and (max-width: 400px) {
  .nav-text {
    height: 40px;
  }
  .header__logo {
    height: 40px;
  }
}
