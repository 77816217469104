.fc-dallas__blogs-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  grid-column-gap: 16px;
  width: calc(100% - 100px);
  max-width: 1400px;
  margin: 0 auto 0;
}

.fc-dallas__blogs {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 970px) {
  .fc-dallas__blogs-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
  }
  .fc-dallas__blogs {
    grid-row: 1;
  }
}

@media screen and (max-width: 950px) {
  .fc-dallas__blogs-container {
    width: calc(100% - 40px);
  }
}
