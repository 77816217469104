.sidebar-dropdown-bulletpoint {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 50px;
  font-family: "Roboto Condensed", sans-serif, Arial;
  margin-top: -50%;
  transition: all 0.3s;
}

.sidebar-dropdown-bulletpoint a {
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 500;
  font-family: "poppins";
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.sidebar-dropdown-bulletpoint a:hover {
  background-color: #e5e5e5;
  color: black;
}

.sidebar-dropdown-bulletpoint__expanded {
  margin-top: 0;
}

.sidebar-dropdown-bulletpoint__text {
  padding: 0;
  font-size: 19px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
