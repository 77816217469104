.g-review {
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: calc(100% - 100px);
  max-width: 1400px; */
  /* max-width: 1000px; */
  /* width: 100%; */
  margin: 0 0 50px;
  /* padding: 16px; */
  border: #80808052 solid 1px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.g-review::before {
  z-index: 3;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 70px solid transparent;
  border-bottom: 70px solid white;
}

.g-review__header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  background-color: black;
  padding: 8px;
}

.fcdallas {
  background: radial-gradient(
    circle,
    rgba(0, 45, 114, 1) 0%,
    rgba(0, 90, 156, 1) 100%
  );
}

.northTexasSc {
  background: radial-gradient(
    circle,
    rgba(0, 45, 114, 1) 0%,
    rgba(0, 90, 156, 1) 100%
  );
}

.dallasTrinityFc {
  background: #37031c;
  background: radial-gradient(at center, #37031c, #7377e2);
}

.g-review__header-image {
  height: 64px;
}

.g-review__team-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.g-review__team-name {
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin: 0;
}

.g-review__team-record {
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin: 0;
}

.g-review__blog-container {
  background: white;
  display: grid;
  /* grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 16px; */
  height: 400px;
  /* max-width: 1400px; */
  padding: 16px;
}

.g-review__overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.g-review__primary-blog {
  position: relative;
  overflow: hidden;
  grid-column: 1;
  grid-row: 1 / span 2;
  cursor: pointer;
}

.g-review__primary-blog-info-container {
  z-index: 2;
  overflow: hidden;
  width: -webkit-fill-available;
  display: grid;
  background-color: transparent;
  position: absolute;
  bottom: 0px;
  margin: 0 10px 30px 30px;
}

.g-review__primary-blog-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  /* -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; */
  transition: transform 0.3s ease;
  object-fit: cover;
  min-height: 100%;
}

.g-review__primary-blog:hover .g-review__primary-blog-image {
  transform: scale(1.1);
}

.g-review__primary-blog-keyword {
  position: relative;
  background-color: #ff4500;
  color: #ffffff;
  width: fit-content;
  font-weight: 500;
  font-family: "poppins";
  font-size: 12px;
  line-height: 16px;
  display: block;
  padding: 2px 5px;
  margin: 10px 0;
}

.g-review__primary-blog-title {
  color: white;
  margin: 0px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-family: "poppins";
  font-size: 16px;
  line-height: 24px;
}

.g-review__primary-blog-author-container {
  display: flex;
  flex-direction: row;
  justify-self: left;
  font-weight: 500;
  font-family: "poppins";
  font-size: 16px;
  line-height: 24px;
  /* display: inline-grid; */
  /* margin-top: 10px; */
}

.g-review__primary-blog-author {
  justify-self: left;
  font-weight: 600;
  font-family: "poppins";
  font-size: 16px;
  line-height: 24px;
  margin-right: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: relative;
  color: #ff4500;
  height: fit-content;
  align-self: flex-end;
  margin: 10px 5px 0 0;
  will-change: transform;
}

.g-review__primary-blog-divider {
  width: 2px;
  height: 15px;
  background-color: #888888;
  margin: 0 0 5px;
  display: inline-block;
  align-self: flex-end;
}

.g-review__primary-blog-time {
  color: #ffffff;
  align-self: flex-end;
  margin: 10px 0 0 5px;
}
